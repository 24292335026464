<template>
  <!-- 判断问题 -->
  <div class="judgeQues">
    <div class="judgeBox" v-if="!paperType">
      <div class="judgeTitle">{{trainItem.Title}} — 习 &nbsp; 题</div>
      <div class="accuracy"></div>
      <ul class="quesList">
        <li v-for="(item,index) in listData" :key="index">
          <div>{{index + 1}}. {{item.Title}}（）</div>
          <van-radio-group v-model="item.optionValue" direction="horizontal" v-if="item.Category == '单选' || item.Category == '判断'">
            <van-radio v-for="(item2,index2) in item.OptionsList" :key="index2" :name="item2.OptionsNum">{{item2.OptionsNum}}. {{item2.Options}}</van-radio>
          </van-radio-group>

          <van-checkbox-group v-model="item.optionValue" v-if="item.Category == '多选'">
            <van-checkbox v-for="(item2,index2) in item.OptionsList" :key="index2" :name="item2.OptionsNum">{{item2.OptionsNum}}. {{item2.Options}}</van-checkbox>
          </van-checkbox-group>
        </li>
      </ul>
      <div class="btn">
        <van-button type="info" @click="submit">提交</van-button>
      </div>
    </div>

    <div class="judgeBox" v-else>
      <div class="judgeTitle">{{trainItem.Title}} — 答题情况及解析</div>
      <div class="accuracy">正确率：{{accuracyValue}}</div>
      <ul class="quesList">
        <li v-for="(item,index) in answerList" :key="index">
          <div>
            <span>{{index + 1}}. {{item.Title}}（{{item.UserAnswer}}）</span>
            <span v-if="item.UserAnswer == item.Answer" style="color:#00ca08; margin-left:20px">答案：{{item.Answer}}</span>
            <span v-else style="color:red; margin-left:20px">答案：{{item.Answer}}</span>
          </div>
          <div class="subjectList">
            <div v-for="(item2,index2) in item.OptionsList" :key="index2">{{item2.OptionsNum}}. {{item2.Options}}</div>
          </div>
          <div class="analysisBox">
            <div class="analysisTop" @click="seeMain(item)">
              <van-icon name="eye" /> <span>查看解析</span>
            </div>
            <div class="analysisMain" v-if="item.analyzShow">
              {{item.Analyzing}}
            </div>
          </div>
        </li>
      </ul>
      <div class="btn">
        <van-button type="info" @click="affirm">确认</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      pattemNmae: "",
      paperType: "",
      accuracyValue: "", // 正确率
      answerList: [],
      userId: "",
      projectId: "",
    }
  },
  props: ["trainItem"],
  methods: {
    getInitData(id, pattemNmae) {
      let para = {
        LearningContentsID: id,
        ItemName: pattemNmae
      }
      this.$axios.post('api/LearningContents/LearningList', para).then(res => {
        this.listData = JSON.parse(res.data).data.QuestionsList;
        this.listData.forEach(v => {
          if (v.Category == "判断" || v.Category == "单选") {
            this.$set(v, "optionValue", "");
          } else if (v.Category == "多选") {
            this.$set(v, "optionValue", []);
          }
        });
      });
    },
    submit() {
      let para = [];
      this.listData.forEach(v => {
        if (v.Category == "多选") {
          this.$set(v, "optionValue", v.optionValue.join(","));
        }
        let newObj = {
          UserId: this.userId,
          QuestionsID: v.ID,
          UserAnswer: v.optionValue,
        }
        para.push(newObj);
      });
      this.$axios.post('api/LearningContents/SubmitAnswers', para).then(res => {
        if (JSON.parse(res.data).code == 200) {
          this.paperType = true;
          this.answerData(this.userId, this.trainItem.ID, this.pattemNmae);
        }
      });

    },
    answerData(userId, id, pattemNmae) {
      let para = {
        UserID: userId,
        LearningContentsID: id,
        ItemName: pattemNmae,
      };
      this.$axios.post('api/LearningContents/QuestionsResult', para).then(res => {
        let a = JSON.parse(res.data);
        if (a.code == 200) {
          let sum = 0;
          this.answerList = JSON.parse(res.data).data;
          this.answerList.forEach(v => {
            this.$set(v, "analyzShow", false);
            if (v.Answer == v.UserAnswer) {
              sum++;
            }
          });
          this.$emit("completeObj");
          this.accuracyValue = (sum / this.answerList.length).toFixed(2) * 100 + "%";
        }
      });
    },
    // 提交完成后点击确认退出查看结果
    affirm() {
      this.$emit("affirm", false);
    },
    // 查看解析
    seeMain(item) {
      item.analyzShow = !item.analyzShow;
    },
  },
  mounted() {
    this.pattemNmae = this.cookies.kkGet("pattemNmae");
    this.userId = this.cookies.kkGet("userId");
    this.paperType = this.trainItem.IsFinish;
    if (this.paperType) {
      this.answerData(this.userId, this.trainItem.ID, this.pattemNmae);
    } else {
      this.getInitData(this.trainItem.ID, this.pattemNmae);
    }


  }
}
</script>

<style>
.judgeQues {
  width: 100%;
  height: 100%;
  background: #eff0ef;
  background: url("../../assets/img/bgimg.png");
  background-size: 100% 100%;
  padding-top: 50px;
}
.judgeBox {
  width: 80%;
  height: 95%;
  background: #fff;
  margin-left: 10%;
  padding: 20px 5%;
  overflow: auto;
}
.judgeBox .judgeTitle {
  font-size: 20px;
  font-weight: bold;
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  color: #333;
  padding-bottom: 20px;
}
.judgeBox .accuracy {
  font-size: 16px;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px dashed #333;
}
.judgeBox .quesList li {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #333;
}
.judgeBox .quesList .van-radio {
  margin-top: 20px;
}
.judgeBox .quesList .van-checkbox {
  margin-top: 20px;
}
.judgeQues .btn {
  text-align: center;
  margin-top: 20px;
}
.judgeQues .btn .van-button--normal {
  padding: 0 30px;
  border-radius: 5px;
  height: 36px;
}
.judgeQues .subjectList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.judgeQues .subjectList div {
  margin-left: 20px;
  margin-top: 5px;
}
.judgeQues .analysisBox {
  margin-top: 20px;
}
.judgeQues .analysisBox .analysisTop {
  display: flex;
  align-items: center;
  color: #008cfd;
}
.judgeQues .analysisBox .analysisTop .van-icon {
  font-size: 16px;
  margin-right: 10px;
}
.judgeQues .analysisBox .analysisMain {
  margin-top: 10px;
}
</style>